<template>
  <div class="history">
    <span class="font-red">每个文件下载后，将为您保存72小时，点击重新下载即可 </span>
    <el-tabs v-model="currentValue" class="tabs-list" >
      <el-tab-pane
        v-for="(o,oIndex) in tabList"
        :key="oIndex"
        :label="o.label"
        :name="o.name"
        class="tabs-content"
        v-loading="tableLoading">
        <Table :obj="o.data" @sizeChange="sizeChange" @pageChange="pageChange" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Table from "./module/table.vue";
import { getConsumeList } from "../../api/order";
export default {
  components: {
    Table
  },
  data() {
    return {
      currentValue: "first",
      tabList: [
        {
          label: "代下素材",
          name: "first",
          data: {}
        }
        // {
        //   label: "共享素材",
        //   name: "second",
        //   data: []
        // }
      ],
      tableLoading: false,
      pageNo:1,
      pageSize:10
    };
  },
  mounted() {
    this.getTableDatas();
  },
  methods: {
    getTableDatas() {
      this.tableLoading = true;
      this.tabList.forEach((item, index) => {
        let dataform = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          orderType: index + 1
        };
        getConsumeList(dataform).then(result => {
            this.tableLoading = false;
            result.datalists.forEach((item1) => {
              item1.tempUrl = item1.icon.replace('img.taogetu.com','img.gallerydownload.com');
              item1.icon = "https://img.taogetu.com/preview260/" + item1.recCode + ".jpg";
            });
            item.data = result;
          }).catch(error => {
            this.tableLoading = false;
          });
      });
    },
    pageChange(params){
      this.pageNo = params;
      this.pageSize = 10;
      this.getTableDatas();
    },
    sizeChange(param){
      this.pageSize = param;
      this.pageNo = 1;
      this.getTableDatas();
    }
  }
};
</script>

<style lang="scss" scoped>
  .font-red {
    color: #909399;
    float: right;
    margin-top: 12px;
  }
</style>
