<template>
  <div v-loading="tableLoading" >
    <el-table :data="obj.datalists" class="table">
      <el-table-column type="index" width="40"></el-table-column>
      <el-table-column label="素材ID" prop="recCode" width="140" class="font-bold"></el-table-column>
      <el-table-column label="图片" width="180">
        <template slot-scope="scope">
          <!-- <img :src="scope.row.icon" alt  v-if="scope.row.webType != 'shutterstock'" height="80px"/> -->
          <el-image :src="scope.row.icon" >
            <div slot="error" class="image-slot">
              <img :src="scope.row.tempUrl" alt height="80px" />
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="素材网站" prop="webType" width="140" class="font-bold">
        <template slot-scope="scope">
           <p v-if="scope.row.webType == 'shutterstock'"> spic </p>
           <p v-else>{{scope.row.webType}}</p>
        </template>
      </el-table-column>
      <el-table-column label="格式" prop="formatName" width="60" class="font-bold"></el-table-column>
      <el-table-column label="价格" prop="price" width="60" class="font-bold"></el-table-column>
      <el-table-column label="下载时间" prop="buyTime" class="font-gray"></el-table-column>
      <el-table-column label="操作状态">
        <template slot-scope="scope"  v-if="scope.row.source != 16">
          <div v-if="scope.row.orderType == null">
            <el-button type="danger" size="small" @click="download(scope.row,scope.$index)">免费重下</el-button>
          </div>
          <div v-else>
            <a rel="nofollow" :href="scope.row.downurl" target="_blank" ><el-button type="primary" size="small" >点击下载</el-button></a>
            <a rel="nofollow" :href="scope.row.downurl2" target="_blank" ><el-button type="success" size="small"  style="margin-left:10px;">海外下载</el-button></a>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="obj.total"
      v-if="obj.total > 10"
    ></el-pagination>
  </div>
</template>

<script>
import {reDownload} from '../../../api/downLoad'
export default {
  props: {
    obj:{
      type:Object
    },
    // 带有默认值的对象
    data: {
      type: Array
    }
  },
  computed: {
    tableData() {
      return this.data;
    }
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      tableLoading: false
    };
  },
  methods: {
    download(item,index) {
      this.tableLoading = true;
      let buydata = {
        imageID:item.recCode,
        webType:item.webType,
        format:item.format
      }
      reDownload(buydata).then((result) => {
        this.tableLoading = false;
        this.$set(this.obj.datalists[index],"downurl",result.downloadUrl);
        this.$set(this.obj.datalists[index],"downurl2",result.downloadUrl2);
        this.obj.datalists[index].orderType = '1';
        console.log(JSON.stringify(this.obj.datalists[index]));
      }).catch((error) => {
        this.tableLoading = false;
      })
    },
    download2(item) {
      this.tableLoading = true;
      let buydata = {
        imageID:item.recCode,
        webType:item.webType,
        format:item.format
      }
      reDownload(buydata).then((result) => {
        this.tableLoading = false;
        window.open(result.downloadUrl2,"_blank");
      }).catch((error) => {
        this.tableLoading = false;
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.$emit("sizeChange", val);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$emit("pageChange", val);
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-image {
      margin-right: 10px;
      float: left;
      width: 60px;
      height: 60px;
      border-radius: 4px;
    }

</style>